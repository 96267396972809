import * as React from "react";
import { Helmet } from "react-helmet";

import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import ErrorPage from "../components/errorPage/errorPage";
import SEO from "../components/seo/SEO";
import Layout from "../components/layout/Layout";

export default function Error() {
  return (
    <Layout>
      <SEO />
      <Helmet>
        <title>Error - /meet 4 Slack</title>
      </Helmet>
      <ErrorPage />
    </Layout>
  );
}
