import * as React from "react";

export default function ErrorPage() {
  return (
    <div className="min-h-full pt-32 pb-12 flex flex-col bg-white">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-16">
          <div className="text-center">
            <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">Oh oh!</p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              Something went wrong...
            </h1>
            <p className="mt-5 mb-5 text-2xl text-gray-500">
              There were some issues getting your account ready. Please try again. If you are still
              facing issues,{" "}
              <a href="mailto:help@meetslack.com" className="text-indigo-600 hover:text-indigo-500">
                contact us
              </a>
              .
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}
